<template>
  <div class="app-body">
    <div class="wrapper w-100 m-3">
      <div class="animated fadeIn">
        <b-row class="justify-content-center">
          <b-col xl="8" lg="10">
            <b-card header-tag="header" footer-tag="footer">
              <div slot="header">
                <i class="fa fa-user"></i><strong> Mon profil</strong>
              </div>
              <b-tabs vertical card pills lazy>
                <b-tab title="> Infos de base" active>
                  
                  <h5>Informations de base</h5>
                  <description-message>
                    Vous pouvez modifier vos informations de base : Code Client, Nom, Prénom, Email et
                    l'authentification
                    à double facteur.
                  </description-message>
                  
                  <b-input-group class="mb-3 mt-3">
                    <b-form-text>
                      Votre code client
                      <b-badge pill class="font-sm ml-2" variant="info">{{myClientCode}}</b-badge>
                      <info-message>
                        Vous fournirez ce code à un administrateur pour qu'il puisse vous ajouter à son exploitation
                        agricole
                      </info-message>
                    </b-form-text>
                  </b-input-group>
                  
                  <c-input container-class="mb-3" label="Votre nom" placeholder="Ex: KRATOS"
                           v-model="lastName" :state="lastNameState">
                    Veuillez saisir un nom
                  </c-input>
                  
                  <c-input container-class="mb-3" label="Votre prénom" placeholder="Ex: Bob"
                           v-model="firstName" :state="firstNameState">
                    Veuillez saisir un prénom
                  </c-input>
                  
                  <c-input container-class="mb-3" label="Votre adresse email" placeholder="Ex: bob@gmail.com"
                           v-model="email" :state="emailState">
                    Veuillez saisir une adresse email valide
                  </c-input>
                  
                  <b-row class="mb-5 text-center row">
                    <b-col>
                      <b-form-checkbox class="text-muted cursor-pointer d-inline-block"
                                       v-model="twoFactorsAuthentication"
                                       switch>
                        Authentification à deux étapes
                      </b-form-checkbox>
                      <info-message>
                        En l'activant, chaque fois que vous vous connecterez à votre compte, nous vous identifierons en
                        fonction des modes choisis
                      </info-message>
                    </b-col>
                    <b-col class="text-left" v-if="twoFactorsAuthentication">
                      <b-form-checkbox class="text-muted cursor-pointer" v-model="emailAuthentication">
                        Envoyer le code par courriel
                      </b-form-checkbox>
                      <b-form-checkbox class="text-muted cursor-pointer" v-model="smsAuthentication"
                                       :disabled="!allowSmsAuthentication" :title="smsAuthenticationTitle"
                                       v-b-tooltip.hover>
                        Envoyer le code par SMS
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  
                  <div class="text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitBasicInfos"
                                    :fetching="fetchingBasicInfos">
                      Enregistrer
                    </button-spinner>
                  </div>
                </b-tab>
                
                <b-tab title="> Numéro de téléphone">
                  
                  <h5>Numéro de téléphone</h5>
                  <description-message>
                    Vous pouvez modifier votre numéro de télephone
                  </description-message>
                  <c-input container-class="mb-3 mt-3" type="tel"
                           label="Votre numéro de téléphone" placeholder="Ex: 680125630"
                           v-model="phoneNumber" :state="phoneNumberState">
                    Téléphone invalide
                  </c-input>
                  
                  <div v-if="phoneConfirmSent">
                    <b-form-text class="mb-3 font-sm">
                      Un code de vérification a été envoyé par SMS au numéro de téléphone {{phoneNumber}}.
                      Veuillez entrer ce code pour continuer.
                    </b-form-text>
                    <b-form-text class="mb-4 font-sm">
                      Vous n'avez pas reçu de SMS ?
                      <b-link block variant="primary" type="button" @click="onResendConfirmCode">Cliquez ici</b-link>
                      pour renvoyer
                    </b-form-text>
                    <c-input container-class="mb-3" label="Code de vérification" placeholder="Ex: 123456"
                             v-model="phoneConfirm" :state="phoneConfirmState">
                      Code de confirmation invalide
                    </c-input>
                  </div>
                  
                  <div class="text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitPhoneNumber"
                                    :fetching="fetchingPhoneNumber">
                      Enregistrer
                    </button-spinner>
                  </div>
                </b-tab>
                
                <b-tab title="> Questions secrètes">
                  
                  <h5>Questions secrètes</h5>
                  <div class="text-center mt-3" v-if="fetchingQuestionList">
                    <b-spinner></b-spinner>
                    <br>
                    Chargement...
                  </div>
                  <div v-else-if="questionList.length">
                    <div v-if="secretQuestionsAvailable">
                      <description-message>
                        Les questions secrètes aident comme une mesure de securité dans l'application. En effet, pour
                        avoir accès
                        à certaines actions comme l'ajout d'un utilisateur à votre ferme, une question vous sera posée.
                        Vous pouvez ci-dessous modifier vos réponses et leurs reponses
                      </description-message>
                      <b-input-group class="mb-3 mt-3">
                        <b-input-group-prepend>
                          <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select v-model="question1" :options="questionOptions[0]" :state="question1State"
                                       aria-describedby="question1-feedback">
                          <template slot="first">
                            <option :value=null disabled>-- Question secrète 1 --</option>
                          </template>
                        </b-form-select>
                        <b-form-input type="text" class="form-control" placeholder="Réponse"
                                      v-model="answer1" :state="question1State" />
                        <b-form-invalid-feedback id="question1-feedback">
                          Sélectionnez une question et entrez une réponse
                        </b-form-invalid-feedback>
                      </b-input-group>
                      
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select v-model="question2" :options="questionOptions[1]" :state="question2State"
                                       aria-describedby="question2-feedback">
                          <template slot="first">
                            <option :value=null disabled>-- Question secrète 2 --</option>
                          </template>
                        </b-form-select>
                        <b-form-input type="text" class="form-control" placeholder="Réponse"
                                      v-model="answer2" :state="question2State" />
                        <b-form-invalid-feedback id="question2-feedback">
                          Sélectionnez une question et entrez une réponse
                        </b-form-invalid-feedback>
                      </b-input-group>
                      
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select v-model="question3" :options="questionOptions[2]" :state="question3State"
                                       aria-describedby="question1-feedback">
                          <template slot="first">
                            <option :value=null disabled>-- Question secrète 3 --</option>
                          </template>
                        </b-form-select>
                        <b-form-input type="text" class="form-control" placeholder="Réponse"
                                      v-model="answer3" :state="question3State" />
                        <b-form-invalid-feedback id="question3-feedback">
                          Sélectionnez une question et entrez une réponse
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                    
                    <b-form-text v-if="!secretQuestionsAvailable" class="mt-3">
                      <description-message>
                        Veuillez saisir votre mot de passe pour avoir accès à vos questions secrètes
                      </description-message>
                    </b-form-text>
                    <c-input container-class="mb-3 mt-2" type="password" label="Mot de passe"
                             placeholder="Votre mot de passe" v-model="questionsPassword"
                             :state="questionsPasswordState">
                      Veuillez saisir un mot de passe
                    </c-input>
                    <div class="text-center">
                      <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitSecretQuestions"
                                      :fetching="fetchingSecretQuestions">
                        Valider
                      </button-spinner>
                    </div>
                  </div>
                  <p class="text-center mt-3" v-else>Echec du chargement des données.</p>
                </b-tab>
                
                <b-tab title="> Mot de passe">
                  
                  <h5>Mot de passe</h5>
                  <description-message>
                    Vous utiliserez le mot de passe pour vous connecter à l'application. Vous pouvez modifier votre mot
                    de passe
                  </description-message>
                  <c-input container-class="mb-3 mt-3" type="password" label="Mot de passe"
                           placeholder="Votre mot de passe" v-model="oldPassword"
                           :state="oldPasswordState">
                    Veuillez saisir l'ancien mot de passe pour changer de mot de passe
                  </c-input>
                  
                  <c-input container-class="mb-3" type="password" label="Nouveau mot de passe"
                           placeholder="Entrez votre nouveau mot de passe" v-model="password"
                           :state="passwordState">
                    Votre mot de passe doit avoir au moins 8 caractères, contenir au moins un chiffre, au moins un
                    caractère spécial et au moins un caractère alphabétique
                  </c-input>
                  <c-input container-class="mb-3" type="password" label="Confirmation de mot de passe"
                           placeholder="Ressaisir votre nouveau mot de passe" v-model="passwordConfirm"
                           :state="passwordConfirmState">
                    Les mots de passe ne correspondent pas
                  </c-input>
                  <div class="text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitPassword"
                                    :fetching="fetchingPassword">
                      Enregistrer
                    </button-spinner>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
  import {Regex, Api, Toast, Storage, String} from "../../helpers"
  
  export default {
    name: 'Profile',
    title: 'PIA - Mon profil',
    components: {},
    data() {
      return {
        user: null,
        
        // Basic infos
        firstName: '',
        lastName: '',
        email: '',
        watchersLocked: true,
        twoFactorsAuthentication: false,
        emailAuthentication: false,
        smsAuthentication: false,
        submittedBasicInfos: false,
        fetchingBasicInfos: false,
        
        // Phone number
        phoneNumber: '',
        phoneConfirm: '',
        phoneConfirmSent: false,
        submittedPhoneNumber: false,
        fetchingPhoneNumber: false,
        
        // Secret questions
        questionList: [],
        question1: null,
        question2: null,
        question3: null,
        answer1: '',
        answer2: '',
        answer3: '',
        questionsPassword: '',
        secretQuestionsAvailable: false,
        submittedSecretQuestions: false,
        fetchingSecretQuestions: false,
        
        // Change password
        oldPassword: '',
        password: '',
        passwordConfirm: '',
        submittedPassword: false,
        fetchingPassword: false,
        
        successModal: false,
        
        submitted: false,
        submittedPersonalData: false,
        submittedPhoneConfirm: false,
        
        error: null,
        fetching: false,
        
        fetchingQuestionList: false,
      }
    },
    created() {
      this.fetchingQuestionList = true
      Api.get('/auth/question/list')
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.questionList = res.data.data.map(question => ({
              value: question.id,
              text: question.name
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingQuestionList = false
        })
      
      this.user = Storage.get('user')
      
      this.firstName = this.user.firstName
      this.lastName = this.user.lastName
      this.email = this.user.email
      this.emailAuthentication = this.user.emailAuthentication
      this.smsAuthentication = this.user.smsAuthentication
      this.twoFactorsAuthentication = this.emailAuthentication || this.smsAuthentication
      setTimeout(() => this.watchersLocked = false, 1000)
      
      this.phoneNumber = this.user.phoneNumber
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
      twoFactorsAuthentication(newVal) {
        if (!this.watchersLocked) {
          this.emailAuthentication = newVal
          if (!newVal || this.allowSmsAuthentication)
            this.smsAuthentication = newVal
        }
      },
      emailAuthentication(newVal) {
        if (!this.watchersLocked) {
          if (!newVal && !this.smsAuthentication)
            this.twoFactorsAuthentication = false
        }
      },
      smsAuthentication(newVal) {
        if (!this.watchersLocked) {
          if (!newVal && !this.emailAuthentication)
            this.twoFactorsAuthentication = false
        }
      }
    },
    computed: {
      myClientCode() {
        return String.toNDigits(this.user.id, 6)
      },
      firstNameState() {
        return !this.submittedBasicInfos || this.firstName.trim().length >= 3 ? null : false
      },
      lastNameState() {
        return !this.submittedBasicInfos || this.lastName.trim().length >= 3 ? null : false
      },
      emailState() {
        return !this.submittedBasicInfos || Regex.isEmail(this.email) ? null : false
      },
      allowSmsAuthentication() {
        return Regex.isCMRPhoneNumber(this.phoneNumber)
      },
      smsAuthenticationTitle() {
        return this.allowSmsAuthentication
          ? ""
          : "Pour activer l'envoi de code par SMS vous devez avoir un numéro de téléphone du Cameroun"
      },
      
      phoneNumberState() {
        return !this.submittedPhoneNumber || Regex.isPhoneNumber(this.phoneNumber) ? null : false
      },
      phoneConfirmState() {
        return !this.submittedPhoneNumber || Regex.isValidPhoneConfirmationCode(this.phoneConfirm) ? null : false
      },
      
      questionOptions() {
        return [
          this.questionList.filter(question => question.value !== this.question2 && question.value !== this.question3),
          this.questionList.filter(question => question.value !== this.question1 && question.value !== this.question3),
          this.questionList.filter(question => question.value !== this.question1 && question.value !== this.question2),
        ]
      },
      questions() {
        return [
          {
            questionId: this.question1,
            answer: this.answer1
          },
          {
            questionId: this.question2,
            answer: this.answer2
          },
          {
            questionId: this.question3,
            answer: this.answer3
          }
        ]
      },
      question1State() {
        return !this.submittedSecretQuestions || (this.question1 != null && this.answer1.trim().length >= 3) ? null : false
      },
      question2State() {
        return !this.submittedSecretQuestions || (this.question2 != null && this.answer2.trim().length >= 3) ? null : false
      },
      question3State() {
        return !this.submittedSecretQuestions || (this.question3 != null && this.answer3.trim().length >= 3) ? null : false
      },
      questionsPasswordState() {
        return !this.submittedSecretQuestions || this.questionsPassword.length > 0 ? null : false
      },
      
      oldPasswordState() {
        return !this.submittedPassword || this.oldPassword.length > 0 ? null : false
      },
      passwordState() {
        return !this.submittedPassword || Regex.isPassword(this.password) ? null : false
      },
      passwordConfirmState() {
        return !this.submittedPassword || this.passwordConfirm === this.password ? null : false
      },
      
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    methods: {
      closeModal() {
        this.successModal = false
        this.$router.push('/home')
      },
      validBasicInfos() {
        return this.firstName.trim().length >= 3
          && this.lastName.trim().length >= 3
          && Regex.isEmail(this.email)
      },
      onSubmitBasicInfos() {
        this.submittedBasicInfos = true
        if (!this.validBasicInfos())
          return
        
        this.fetchingBasicInfos = true
        Api.post('/user/update/basic-infos', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          emailAuthentication: this.emailAuthentication,
          smsAuthentication: this.smsAuthentication,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              const emailChanged = this.email !== this.user.email
              Storage.store('user', {
                ...res.data.data,
                email: this.user.email
              })
              
              let messageToDisplay = 'Votre profil a été mis à jour avec succès';
              if (emailChanged)
                 messageToDisplay += " et un email de confirmation de votre nouvelle adresse vous a été envoyé à " + this.email
              
              Toast.success(messageToDisplay)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(e => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingBasicInfos = false
          })
      },
      onResendConfirmCode() {
        this.phoneConfirmSent = false
        this.onSubmitPhoneNumber()
      },
      validPhoneNumber() {
        return !this.phoneConfirmSent
          ? Regex.isPhoneNumber(this.phoneNumber)
          : Regex.isValidPhoneConfirmationCode(this.phoneConfirm)
      },
      onSubmitPhoneNumber() {
        this.submittedPhoneNumber = true
        if (!this.validPhoneNumber())
          return
        
        if (this.phoneNumber === this.user.phoneNumber) {
          Toast.error('Veuillez modifier le numéro avant de soumettre !')
          return
        }
        
        if (!this.phoneConfirmSent) {
          this.fetchingPhoneNumber = true
          Api.post('/user/update/request-phone-confirm-code', {
            phoneNumber: this.phoneNumber
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.error = null
                
                this.phoneConfirm = ''
                this.phoneConfirmSent = true
                this.submittedPhoneNumber = false
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(e => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingPhoneNumber = false
            })
        }
        else {
          this.fetchingPhoneNumber = true
          Api.post('/user/update/phone-number', {
            phoneNumber: this.phoneNumber,
            code: this.phoneConfirm
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.error = null
                Storage.store('user', res.data.data)
                
                this.phoneConfirmSent = false
                Toast.success('Votre numéro de téléphone a été mis à jour avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(e => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingPhoneNumber = false
            })
        }
      },
      validSecretQuestions() {
        return !this.secretQuestionsAvailable
          ? this.questionsPassword.length > 0
          : this.questionsPassword.length > 0
          && (this.question1 != null && this.answer1.trim().length >= 3)
          && (this.question2 != null && this.answer2.trim().length >= 3)
          && (this.question3 != null && this.answer3.trim().length >= 3)
      },
      onSubmitSecretQuestions() {
        this.submittedSecretQuestions = true
        if (!this.validSecretQuestions())
          return
        
        if (!this.secretQuestionsAvailable) {
          this.fetchingSecretQuestions = true
          Api.post('/user/secret-questions', {
            password: this.questionsPassword
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.error = null
                
                const questions = res.data.data
                this.question1 = questions[0].questionId
                this.answer1 = questions[0].answer
                this.question2 = questions[1].questionId
                this.answer2 = questions[1].answer
                this.question3 = questions[2].questionId
                this.answer3 = questions[2].answer
                
                this.secretQuestionsAvailable = true
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(e => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.submittedSecretQuestions = false
              this.questionsPassword = ''
              this.fetchingSecretQuestions = false
            })
        }
        else {
          this.fetchingSecretQuestions = true
          Api.post('/user/update/secret-questions', {
            questions: this.questions,
            password: this.questionsPassword
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                this.error = null
                
                Toast.success('Vos questions secrètes ont été mises à jour avec succès !')
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(e => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.submittedSecretQuestions = false
              this.questionsPassword = ''
              this.fetchingSecretQuestions = false
            })
        }
      },
      validPassword() {
        return this.oldPassword.length > 0
          && Regex.isPassword(this.password)
          && this.password === this.passwordConfirm
      },
      onSubmitPassword() {
        this.submittedPassword = true
        if (!this.validPassword())
          return
        
        this.fetchingPassword = true
        Api.post('/user/update/password', {
          oldPassword: this.oldPassword,
          password: this.password,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.error = null
              
              Toast.success('Votre mot de passe a été mis à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(e => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.submittedPassword = false
            this.oldPassword = ''
            this.password = ''
            this.passwordConfirm = ''
            this.fetchingPassword = false
          })
      }
    }
  }
</script>

<style scoped>
  @import "~vue-tel-input/dist/vue-tel-input.css";
  
  h5{
    border-bottom: 1px solid black;
  }
  
  .left-icon{
    padding: 0 20px;
  }
  
  .tab-pane{
    min-height: 400px !important;
    padding: 2rem !important;
  }
</style>

<style>
  .tel-input-container{
    width: 100%;
    border-color: #e4e7ea !important;
  }
  
  .tel-input-container .dropdown{
    background-color: #f0f3f5;
  }
  
  .tel-input-container .tel-input{
    border-left: 1px solid #e4e7ea !important;
    padding: 0.375rem 0.75rem !important;
  }
</style>
